import React from "react";
import autoBind from 'react-autobind';
import ReactMarkdown from 'react-markdown';
import { Select, Form, Divider, Switch, Row, Radio, Col, Input, Button, InputNumber, Popover, Tooltip, Dropdown, TimePicker, DatePicker} from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import { ChromePicker } from 'react-color';
import MarkdownEditor from '@ikonintegration/react-rte';
//
import Utils from "../../../components/Utils";
import Globals from "../../../config/Globals";
//
import CommonPopoversContent from "./_CommonPopoversContent";
import CommonSessionDraftFormDatesTable from './CommonSessionDraftFormDatesTable';
import CommonOrganizationSelectionModal from "../OrganizationSelection/CommonOrganizationSelectionModal";
import CommonOrgReferralTable from "../CommonOrgReferralTable";
import moment from "moment";
//

//props: app, courses, venues
export default class CommonSessionDraftForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      type: null,
      templateID: null, resources: MarkdownEditor.createEmptyValue(),
      overview: MarkdownEditor.createEmptyValue(),
      specialInstructions: MarkdownEditor.createEmptyValue(),
      possibleReferrals: [], referralOrgs: null, askForReferrals: false,
      isChargeForMaterialsEnable: false
    };
  }
  get limitedEditModeAttributes() {
    const { limitedEditMode } = this.props;

    return !limitedEditMode
      ? {}
      : { disabled: true }
  }
  //Public
  resetFields(type) {
    this.setState({
      type: null,
      templateID: null
    })
    this.form.resetFields();
  }
  setFieldsValue(data) { //from session obj
    let onlineAccessPeriodObject = this._getOnlineAccessPeriodObject(data.onlineAccessPeriod);
    const resources = data?.content?.sections?.[0]?.content;
    const overview = data?.overview;
    const specialInstructions = data?.specialInstructions;
    const duration = moment(Math.floor(+data?.duration/60)+':'+data?.duration % 60, 'HH:mm');
    const allowEnrolmentUntil = data.allowEnrolmentUntil ? Utils.getDateOnMomentFormatByTimestamp(data.allowEnrolmentUntil) : null;
    this.setState({
      type: data.type,
      templateID: data.templateID, askForReferrals: !!data?.askForReferrals, possibleReferrals: data?.possibleReferrals || [],
      resources: resources ? MarkdownEditor.createValueFromString(resources, 'markdown') : MarkdownEditor.createEmptyValue(),
      overview: overview ? MarkdownEditor.createValueFromString(overview, 'markdown') : MarkdownEditor.createEmptyValue(),
      specialInstructions: specialInstructions ? MarkdownEditor.createValueFromString(specialInstructions, 'markdown') : MarkdownEditor.createEmptyValue(),
      calendarColor: data?.calendarColor,
      isChargeForMaterialsEnable: data?.chargeForMaterials,
      hasMaterialNone: data?.hasMaterial === Globals.Session_HasMaterial.NONE,
      duration: data?.duration
    }, () => {
      this.form.setFieldsValue({
        ...data, ...data.notificationDetails,
        adminEmails: data.notificationDetails?.adminEmails?.join(', ') || '',
        ...onlineAccessPeriodObject, duration, allowEnrolmentUntil
      });
      this._loadReferralNames();
      if (this.datesTable) {
        const dates = data.startDate?.map(
          (startDate, index) => ({
            id: index,
            startDate: Utils.getDateOnMomentFormatByTimestamp(startDate),
            startTime: Utils.getDateOnMomentFormatByTimestamp(startDate),
            endTime: data.endDate ? (data.endDate[index] ? Utils.getDateOnMomentFormatByTimestamp(data.endDate[index]) : null) : null
          }),
        ) || [];

        this.datesTable.setDates(dates);
      }

      if (this.weekDayTable && data?.launchSchedule?.length > 0) {
        const launchSchedule = data.launchSchedule.map((schedule, index) => {
          const [startHour, startMinute] = schedule.startTime.split(':');
          const [endHour, endMinute] = schedule.endTime.split(':');

          return {
            id: index,
            dayOfWeek: String(schedule.dayOfWeek),
            startTime: moment().hour(startHour).minute(startMinute).second(0),
            endTime: moment().hour(endHour).minute(endMinute).second(0),
          };
        });

        this.weekDayTable.setDates(launchSchedule);
      }
    });
  }
  setFieldsValueFromTemplate(data) {
    let onlineAccessPeriodObject = this._getOnlineAccessPeriodObject(data.onlineAccessPeriod);
    const resources = data?.content?.sections?.[0]?.content;
    const overview = data?.overview;
    const specialInstructions = data?.specialInstructions;
    const calendarColor = data.calendarColor || Utils.randomHexColor();
    const duration = moment(Math.floor(+data?.duration/60)+':'+data?.duration % 60, 'HH:mm');
    this.setState({
      type: data.type, templateID: data.id, askForReferrals: !!data?.askForReferrals, possibleReferrals: data?.possibleReferrals || [],
      resources: resources ? MarkdownEditor.createValueFromString(resources, 'markdown') : MarkdownEditor.createEmptyValue(),
      overview: overview ? MarkdownEditor.createValueFromString(overview, 'markdown') : MarkdownEditor.createEmptyValue(),
      specialInstructions: specialInstructions ? MarkdownEditor.createValueFromString(specialInstructions, 'markdown') : MarkdownEditor.createEmptyValue(),
      calendarColor: calendarColor,
      isChargeForMaterialsEnable: data.chargeForMaterials,
      hasMaterialNone: data.hasMaterial === Globals.Session_HasMaterial.NONE,
      duration: data?.duration
    }, () => {
      this.form.setFieldsValue({
        ...data, ...data.notificationDetails,
        adminEmails: data.notificationDetails?.adminEmails?.join(', ') || '',
        ...onlineAccessPeriodObject, duration
      });
      this._loadReferralNames();
    });
  }
  async validateFields() {
    if (!this.form) return false;
    const formData = await this.form.validateFields();
    if (!formData) return false;
    const onlineAccessPeriod = this._calculateOnlineAccessPeriod(formData.onlineAccessPeriod, formData.onlineAccessPeriodUnity);
    delete formData.onlineAccessPeriodUnity;
    delete formData.resources;

    let datesObject = {
      startDate: [],
      endDate: [],
    };
    if (this.datesTable) {
      const { startDate, endDate } = this.datesTable.getStartAndEndDates();
      datesObject = { startDate, endDate };
    }

    let launchSchedule;
    if (this.weekDayTable) {
      launchSchedule = this.weekDayTable.getWeekdays();

      if (launchSchedule && launchSchedule.length === 0) launchSchedule = undefined;
    }

    const allowEnrolmentUntil = formData.allowEnrolmentUntil ? Utils.getEndOfDayTimestampFromMomentDate(formData.allowEnrolmentUntil) : null;

    return {
      ...formData, ...datesObject, duration: this.state.duration,
      adminEmails: undefined, shouldNotifyEnrolmentToAdmins: undefined, notifyWhenSessionFull: undefined, onlineAccessPeriod,
      templateID: this.state.templateID, dates: undefined, enrolmentCount: undefined, instructors: undefined,
      enrolments: undefined, results: undefined, attendances: undefined, possibleReferrals: this.state.possibleReferrals || [],
      overview: this.state.overview.toString('markdown'), possibleReferrals: this.state.possibleReferrals || [], launchSchedule,
      specialInstructions: this.state.specialInstructions.toString('markdown'),
      content: {
        sections: [
          {
            content: this.state.resources.toString('markdown'),
            enabled: true,
            name: 'Resources',
            order: 0,
          }
        ],
      },
      notificationDetails: {
        adminEmails: (formData?.adminEmails?.length > 0 ? formData?.adminEmails?.replace(/[ ?&/]/g, '')?.split(',') || [] : []),
        shouldNotifyEnrolmentToAdmins: !!formData.shouldNotifyEnrolmentToAdmins,
        notifyWhenSessionFull: !!formData.notifyWhenSessionFull,
      },
      calendarColor: this.state.calendarColor,
      allowEnrolmentUntil
    };
  }
  //Actions
  handleDuration(time, timeString) {
    this.setState({ duration: (+timeString.substr(0,2) * 60) + +timeString.substr(3,2) })
  }
  handleChangeAskForReferrals() { this.setState({ askForReferrals: !this.state.askForReferrals }); }
  //referral actions
  handleReferralSelected(org) {
    const refs = this.state.possibleReferrals || [];
    if (refs.includes(org.id)) return;
    this.setState({ referralOrgs: null, possibleReferrals: [...refs.concat([ org.id ])] }, this._loadReferralNames);
  }
  handleDeleteReferral(orgID) {
    const refs = this.state.possibleReferrals || [];
    refs.splice(refs.indexOf(orgID), 1);
    this.setState({ possibleReferrals: [...refs] });
  }
  handleAddReferral() { this.referralSelectionModal.show(); }
  //material actions
  handleChangeHasMaterial(hasMaterial) { this.setState({ hasMaterialNone: hasMaterial === Globals.Session_HasMaterial.NONE }); }
  handleChangeChargeForMaterials() { this.setState({ chargeForMaterials: !this.state.chargeForMaterials, isChargeForMaterialsEnable: !this.state.isChargeForMaterialsEnable  }); }

  //UI
  render() { return this._renderMainForm(); }

  _calculateOnlineAccessPeriod(onlineAccessPeriod, onlineAccessPeriodUnity) {
    if (!onlineAccessPeriod) return undefined;
    onlineAccessPeriodUnity = onlineAccessPeriodUnity || 'days';

    if (onlineAccessPeriodUnity === 'days') return onlineAccessPeriod * 1440;
    if (onlineAccessPeriodUnity === 'hours') return onlineAccessPeriod * 60;
    return onlineAccessPeriod;
  }
  _getOnlineAccessPeriodObject(onlineAccessPeriod) {
    let onlineAccessPeriodObject = {};

    if (onlineAccessPeriod) {
      if (onlineAccessPeriod >= 1440) {
        onlineAccessPeriodObject.onlineAccessPeriod = onlineAccessPeriod / 1440;
        onlineAccessPeriodObject.onlineAccessPeriodUnity = 'days';
      } else if (onlineAccessPeriod >= 60) {
        onlineAccessPeriodObject.onlineAccessPeriod = onlineAccessPeriod / 60;
        onlineAccessPeriodObject.onlineAccessPeriodUnity = 'hours';
      } else {
        onlineAccessPeriodObject.onlineAccessPeriod = onlineAccessPeriod;
        onlineAccessPeriodObject.onlineAccessPeriodUnity = 'minutes';
      }
    }

    return onlineAccessPeriodObject;
  }

  /* Private UI */
  _renderMainForm() {
    const isSchedulable = !(this.state.type == Globals.Session_Type.ONLINE_EXT || this.state.type == Globals.Session_Type.ONLINE);
    const isOnlineExt = (this.state.type == Globals.Session_Type.ONLINE_EXT || this.state.type == Globals.Session_Type.SCHEDULED_ONLINE_EXT);
    const isVILT = (this.state.type == Globals.Session_Type.SCHEDULED_VILT);
    const isWebinar = (this.state.type == Globals.Session_Type.SCHEDULED_WEBINAR);
    const isPresential = (this.state.type == Globals.Session_Type.SCHEDULED_PRESENTIAL);
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item label="Internal name" name="internalName" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input {...this.limitedEditModeAttributes} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: 'This field is required!' }]}
              label={<>
                Display name
                <Popover title='Display name' content="This field will be displayed to users on their interface.">
                  <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                </Popover>
              </>}
            >
              <Input {...this.limitedEditModeAttributes} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={7}>
            {this._renderDisabledTooltip(
              <Form.Item
                name='courseID'
                rules={[{ required: true, message: 'This field is required!' }]}
                label={<>
                  Course
                  <Popover title='Course' content="What course the sessions created from this template will be related to.">
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>}
              >
                <Select mode='single' showSearch disabled filterOption={(input, option) => {
                  const course = (this.props.courses || []).find((c) => option.value == c.id);
                  return `${course.description}`.toLowerCase().includes(input.toLowerCase());
                }} {...this.limitedEditModeAttributes}>
                  {(this.props.courses || []).map((c) => {
                    return <Select.Option value={c.id} key={c.id}>{c.description}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
            )}
          </Col>
          <Col>
            {this._renderDisabledTooltip(
              <Form.Item label={<>
                Session Type
                <Popover title='Session Types' content={CommonPopoversContent.renderSessionTypesPopoverContent()} placement='right'>
                  <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                </Popover>
              </>} name="type" initialValue={this.state.type}>
                <Radio.Group
                  value={this.state.type}
                  disabled
                  style={{ opacity: 0.5 }}
                  optionType="button"
                  buttonStyle="solid"
                  options={[
                    { value: Globals.Session_Type.SCHEDULED_ONLINE_EXT, label: Globals.getTemplateTypeIcon(Globals.Session_Type.SCHEDULED_ONLINE_EXT, true) },
                    { value: Globals.Session_Type.SCHEDULED_PRESENTIAL, label: Globals.getTemplateTypeIcon(Globals.Session_Type.SCHEDULED_PRESENTIAL, true) },
                    { value: Globals.Session_Type.SCHEDULED_VILT, label: Globals.getTemplateTypeIcon(Globals.Session_Type.SCHEDULED_VILT, true) },
                    { value: Globals.Session_Type.SCHEDULED_WEBINAR, label: Globals.getTemplateTypeIcon(Globals.Session_Type.SCHEDULED_WEBINAR, true) },
                    { value: Globals.Session_Type.ONLINE_EXT, label: Globals.getTemplateTypeIcon(Globals.Session_Type.ONLINE_EXT, true) },
                    { value: Globals.Session_Type.ONLINE, label: Globals.getTemplateTypeIcon(Globals.Session_Type.ONLINE, true) },
                  ]}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
        {this._renderGeneralSubform(isSchedulable)}
        {this._renderMaterialsSubform()}
        {this._renderContentSubform()}
        {this.state.askForReferrals && this._renderReferralSubform()}
        {isSchedulable && this._renderSchedulableSubform(isPresential)}
        {this.state.type == Globals.Session_Type.ONLINE_EXT && this._renderRecurrencySubform()}
        {(isOnlineExt || isWebinar || isVILT) && this._renderOnlineIntegrationSubform()}
        {this._renderNotificationsSubform()}
      </Form>
    );
  }
  /* Subforms */
  _renderGeneralSubform(isSchedulable) {
    const overlay = (
      <div style={{zIndex: 9999}}> <ChromePicker color={this.state.calendarColor} disableAlpha={true}
        onChangeComplete={(calendarColor) => { this.setState({ calendarColor: calendarColor.hex }) }}/> </div>
    );

    return (
      <div style={{ marginTop: 32 }}>
        <Divider orientation="left">Details</Divider>
        <Row gutter={32}>
          <Col span={4}>
            <Form.Item name='capacity' label={<>
              Capacity
              <Popover title='Capacity' content={CommonPopoversContent.renderCapacityPopoverContent()}>
                <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
              </Popover>
            </>}>
              <InputNumber style={{ maxWidth: 140 }} min={-1} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='reservedCapacity' label={<>
              Reserved Capacity
              <Popover title='Reserved Capacity' content={CommonPopoversContent.renderReservedCapacityPopoverContent()}>
                <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
              </Popover>
            </>}>
              <InputNumber style={{ maxWidth: 140 }} min={-1}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='minCapacity' label={<>
              Minimum Capacity
              <Popover title='Minimum Capacity' content={CommonPopoversContent.renderMinimumCapacityPopoverContent()}>
                <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
              </Popover>
            </>}>
              <InputNumber style={{ maxWidth: 140 }} min={-1}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='releaseType' rules={[{ required: true, message: 'This field is required!' }]} label={<>
                Release Type
                <Popover title='Release Type' content={CommonPopoversContent.renderReleaseTypePopoverContent()}>
                  <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                </Popover>
              </>}>
              <Select mode='single' showSearch {...this.limitedEditModeAttributes}>
                <Select.Option disabled={isSchedulable} value={Globals.Session_ReleaseType.NONE} key={Globals.Session_ReleaseType.NONE}>None</Select.Option>
                <Select.Option disabled={!isSchedulable} value={Globals.Session_ReleaseType.AUTO} key={Globals.Session_ReleaseType.AUTO}>Auto</Select.Option>
                <Select.Option value={Globals.Session_ReleaseType.MANUAL} key={Globals.Session_ReleaseType.MANUAL}>Manual</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
          <Form.Item
              name="cancelationPolicyID"
              label={
                <>
                  Cancelation Policy
                  <Popover
                    title="Cancelation Policy"
                    content={CommonPopoversContent.renderCancelationPolicyPopoverContent()}
                  >
                    <QuestionCircleFilled
                      style={{ fontSize: 18, color: "#bcbcbc", marginLeft: 10 }}
                    />
                  </Popover>
                </>
              }
            >
              <Select
                mode="single"
                showSearch
                filterOption={(input, option) => {
                  const cancelationPolicies = (
                    this.props.app.sharedCache().getCancelationPolicies() || []
                  ).find((c) => option.value == c.id);
                  return `${cancelationPolicies.description}`
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                <Select.Option value={""} key="empty-option" />
                {(
                  this.props.app.sharedCache().getCancelationPolicies() || []
                ).map((c) => {
                  return (
                    <Select.Option value={c.id} key={c.id}>
                      {c.description}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="calendarColor" label={<>
                Calendar Color
                <Popover title='Calendar Color' content={CommonPopoversContent.renderCalendarColorPopoverContent()}>
                  <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                </Popover>
              </>}>
              <Dropdown trigger={["click"]} overlay={overlay}>
                <Button style={{ background: this.state.calendarColor, marginLeft: 20 }}> </Button>
              </Dropdown>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={4}>
            <Form.Item name='requiresInstructor' valuePropName='checked' label={<>
              Requires Instructor
              <Popover title='Requires Instructor' content={CommonPopoversContent.renderRequiresInstructorPopoverContent()}>
                <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
              </Popover>
            </>}>
              <Switch />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='requiresAdminReview' valuePropName='checked' label={<>
              Requires Admin Review
              <Popover title='Requires Admin Review' content={CommonPopoversContent.renderRequiresAdminReviewPopoverContent()}>
                <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
              </Popover>
            </>}>
              <Switch />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='askForReferrals' valuePropName='checked' label={<>
              Ask for Affiliates?
              <Popover title='Ask for Affiliates?' content={CommonPopoversContent.renderAskForAffiliatesPopoverContent()}>
                <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
              </Popover>
            </>}>
              <Switch onChange={this.handleChangeAskForReferrals} {...this.limitedEditModeAttributes} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='isPrivate' valuePropName='checked' label={<>
              Private Sessions
              <Popover title='Private Sessions' content={CommonPopoversContent.renderSessionPrivatePopoverContent()}>
                <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
              </Popover>
            </>}>
              <Switch />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="allowEnrolmentUntil"
              label={
                <>
                  Prevent Enrolment After
                  <Popover
                    title="Prevent Enrolment After"
                    content="Session will not accept any new enrolments after this date."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <DatePicker
                placeholder="Date"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={4}>
            <Form.Item name='chargeDeferred' valuePropName='checked' label={<>
              Charge Deferred
              <Popover title='Charge Deferred' content={CommonPopoversContent.renderChargeDeferredPopoverContent()}>
                <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
              </Popover>
            </>}>
              <Switch {...this.limitedEditModeAttributes} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='isEligibleForNoCost' valuePropName='checked' label={<>
              Eligible for No Cost
              <Popover title='Eligible for No Cost' content={CommonPopoversContent.renderIsEligibleForNoCostPopoverContent()}>
                <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
              </Popover>
            </>}>
              <Switch {...this.limitedEditModeAttributes} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='confirmInfoOnEnrolment' valuePropName='checked' label={<>
              Confirm info at enrolment
              <Popover title='Confirm info at enrolment' content={CommonPopoversContent.renderConfirmInfoOnEnrolmentPopoverContent()}>
                <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
              </Popover>
            </>}>
              <Switch />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name='autoEnrolOnSession' label={<>
              Optional Auto Enrol In Session
              <Popover title='Optional Auto Enrol In Session' content={CommonPopoversContent.renderConfirmInfoAutoEnrolmentPopoverContent()}>
                <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
              </Popover>
            </>}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  }
  _renderMaterialsSubform() {
    return (
      <div style={{ marginTop: 32 }}>
        <Divider orientation="left">Materials</Divider>

        <Row gutter={32}>
          <Col span={4}>
            <Form.Item name='hasMaterial' rules={[{ required: true, message: 'This field is required!' }]} initialValue={Globals.Session_HasMaterial.NONE} label={<>
              Course Material
              <Popover title='Course Material' content={CommonPopoversContent.renderHasMaterialPopoverContent()}>
                <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
              </Popover>
            </>}>
              <Select mode='single' showSearch onChange={this.handleChangeHasMaterial} {...this.limitedEditModeAttributes}>
                <Select.Option value={Globals.Session_HasMaterial.NONE} key={Globals.Session_HasMaterial.NONE}>None</Select.Option>
                <Select.Option value={Globals.Session_HasMaterial.ALL} key={Globals.Session_HasMaterial.ALL}>Send to Venue</Select.Option>
                <Select.Option value={Globals.Session_HasMaterial.INDIVIDUAL} key={Globals.Session_HasMaterial.INDIVIDUAL}>Send to Student</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='chargeForMaterials' valuePropName='checked' label={<>
              Charge for Materials
              <Popover title='Charge for Materials' content={CommonPopoversContent.renderChangeForMaterialsPopoverContent()}>
                <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
              </Popover>
            </>}>
              <Switch
                id="chargeForMaterials"
                onChange={this.handleChangeChargeForMaterials}
                disabled={this.state.hasMaterialNone}
                {...this.limitedEditModeAttributes}
              />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              name='courseMaterialProductID'
              rules={[{ required: this.state.isChargeForMaterialsEnable, message: 'This field is required!' }]}
              label={<>
                Course Material Product
                <Popover title='Course Material Product' content={CommonPopoversContent.renderCourseMaterialProductPopoverContent()}>
                  <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                </Popover>
              </>}
            >
              <Select mode='single' showSearch disabled={!this.state.isChargeForMaterialsEnable} filterOption={(input, option) => {
                const product = (this.props.app.sharedCache().getProducts() || []).find((p) => option.value == p.id);
                return `${product.description}`.toLowerCase().includes(input.toLowerCase());
              }}>
                <Select.Option value="" key="none">None</Select.Option>
                {(this.props.app.sharedCache().getProducts() || []).map((p) => {
                  return <Select.Option value={p.id} key={p.id}>{p.description}</Select.Option>;
                })
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='isCourseMaterialShipped' valuePropName='checked' label={<>
              Course Material Shipped
              <Popover title='Course Material Shipped' content={CommonPopoversContent.renderIsCourseMaterialShippedPopoverContent()}>
                <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
              </Popover>
            </>}>
              <Switch
                id="isCourseMaterialShipped"
                disabled={!this.state.isChargeForMaterialsEnable || this.state.hasMaterialNone}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  }
  _renderContentSubform(isSchedulable) {
    return (
      <div style={{ marginTop: 32 }}>
        <Divider orientation="left">Content</Divider>

        <Row>
          <Col span={11}>
            <Form.Item label="Overview" name="overview" valuePropName="none">
              {this.props.editMode && (
                <ReactMarkdown children={this.state.overview?.toString('markdown')} linkTarget='_blank'/>
              )}
              {!this.props.editMode && (
                <MarkdownEditor
                  toolbarConfig={Utils.markdownToolbarItems()}
                  value={this.state.overview}
                  onChange={overview => this.setState({ overview })}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12} offset={1}>
            <Form.Item label="Resources" name="resources" valuePropName="none">
              {this.props.editMode && (
                <ReactMarkdown children={this.state.resources?.toString('markdown')} linkTarget='_blank'/>
              )}
              {!this.props.editMode && (
                <MarkdownEditor
                  toolbarConfig={Utils.markdownToolbarItems()}
                  value={this.state.resources}
                  onChange={resources => this.setState({ resources })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <Form.Item label="Special Instructions" name="specialInstructions" valuePropName="none">
              {this.props.editMode && (
                <ReactMarkdown children={this.state.specialInstructions?.toString('markdown')} linkTarget='_blank'/>
              )}
              {!this.props.editMode && (
                <MarkdownEditor
                  toolbarConfig={Utils.markdownToolbarItems()}
                  value={this.state.specialInstructions}
                  onChange={specialInstructions => this.setState({ specialInstructions })}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12} offset={1}>
            <Form.Item name='lockReason' label={<>
              Reason for session lock
              <Popover title='Reason for session lock' content={CommonPopoversContent.renderLockReasonPopoverContent()}>
                <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
              </Popover>
              </>}>
              <Input.TextArea {...this.limitedEditModeAttributes} />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row>
          <Col span={11}>
            <Form.Item name='internalNotes' label='Internal Notes'>
              <Input.TextArea style={{ height: 180 }}/>
            </Form.Item>
          </Col>
          <Col span={12} offset={1}>
            <Form.Item name='instructorNotes' label='Instructor Notes'>
              <Input.TextArea style={{ height: 180 }}/>
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  }
  _renderReferralSubform() {
    return (
      <>
        <CommonOrganizationSelectionModal {...Utils.propagateRef(this, 'referralSelectionModal')} app={this.props.app}
          allowOrgsListing onSelection={this.handleReferralSelected}/>
        <Divider orientation="left">Referral Settings</Divider>
        <Row gutter={32}>
          <Col span={4}>
            <Form.Item name='isReferralPercentage' valuePropName='checked' label={<>
              Is Referral Amount Percentage of the purchase value?
              <Popover title='Is Referral Amount Percentage of the purchase value?' content={CommonPopoversContent.renderIsReferralPercentagePopoverContent()}>
                <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
              </Popover>
            </>}>
              <Switch />
            </Form.Item>
          </Col>
          <Col span={3} offset={1}>
            <Form.Item name='referralAmount' label={'Referral Amount'} rules={[{ required: true, message: 'This field is required!' }]}>
              <InputNumber min={0} precision={2}/>
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex' style={{ marginTop: 10 }}>
          <Col span={20}>
            <Form.Item name='possibleReferrals' label={'List of Referrals'} rules={[{ validator: (r, v) => {
              return new Promise((resolve, reject) => {
                if (this.state.possibleReferrals?.length > 0) resolve();
                else reject(`One referrals is required!`);
              })
            }}]}>
              <CommonOrgReferralTable ids={this.state.possibleReferrals} onDelete={this.handleDeleteReferral}
                isLoading={this.state.isLoading} orgs={this.state.referralOrgs}/>
            </Form.Item>
          </Col>
          <Col offset={1}> <Button type='primary' onClick={this.handleAddReferral}>Add Referral</Button> </Col>
        </Row>
      </>
    );
  }
  _renderRecurrencySubform() {
    return (
      <div style={{ marginTop: 32 }}>
        <Divider orientation="left">Date</Divider>
        <Row gutter={32}>
          <Col span={24}>
            <CommonSessionDraftFormDatesTable weekDayMode {...Utils.propagateRef(this, 'weekDayTable')} />
          </Col>
        </Row>
      </div>
    );
  }
  _renderSchedulableSubform(isPresential) {
    return (
      <div style={{ marginTop: 32 }}>
        <Divider orientation="left">Date & Time</Divider>
        <Row gutter={32}>
          <Col span={3}>
            <Form.Item name='duration' label={'Duration'} rules={[{ required: true, message: 'This field is required!' }]}>
              <div>
                <TimePicker showNow={false} format="HH:mm" minuteStep={30} onChange={this.handleDuration} value={moment(Math.floor(+this.state.duration/60)+':'+this.state.duration % 60, 'HH:mm')}/>
              </div>
            </Form.Item>
          </Col>
          {isPresential && <Col span={8}>
            <Form.Item name='venueID' label={'Venue'} rules={[{ required: true, message: 'This field is required!' }]}>
              <Select mode='single' showSearch filterOption={(input, option) => {
                const venue = (this.props.venues || []).find((venue) => option.value == venue.id);
                let cityName = this.props.app.sharedCache().getCityByID(venue.cityID)?.name;
                cityName = cityName ? `${cityName}: ` : '';
                return `${cityName}${venue.name}`.toLowerCase().includes(input.toLowerCase());
              }}>
                {(this.props.venues || []).map((venue) => {
                  return (
                    <Select.Option value={venue.id} key={venue.id}>
                      {(() => {
                        const cityName = this.props.app.sharedCache().getCityByID(venue.cityID)?.name;
                        return cityName ? `${cityName}: ` : '';
                      })()}
                      {venue.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>}
          {isPresential && <Col span={11}>
            <Form.Item label="Venue Complement" name="venueComplement" rules={[{ required: false, message: 'This field is required!' }]}>
              <Input />
            </Form.Item>
          </Col>}
          {!isPresential && <Col span={11}> {this._renderDatesRow()} </Col>}
        </Row>
        {isPresential && this._renderDatesRow()}
      </div>
    );
  }
  _renderOnlineIntegrationSubform() {
    const allowedOnlineCourseProviders = this.props.app.sharedCache().getTenantConfig().customer?.allowedOnlineCourseProviders || [];
    const isVILT = (this.state.type == Globals.Session_Type.SCHEDULED_VILT);
    const isWebinar = (this.state.type == Globals.Session_Type.SCHEDULED_WEBINAR);

    return (
      <div style={{ marginTop: 32 }}>
        <Divider orientation="left">Online Integration</Divider>
        <Row gutter={32}>
          {!(isWebinar || isVILT) && (
            <Col span={4}>
              <Form.Item name='considerFailures' valuePropName='checked' label={<>
                Single Quizz Attempt
                <Popover title='Single Quizz Attempt' content={CommonPopoversContent.renderConsiderFailuresPopoverContent()}>
                  <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                </Popover>
              </>}>
                <Switch />
              </Form.Item>
            </Col>
          )}

          <Col span={7} style={{ display: 'flex' }}>
            <Form.Item name='onlineAccessPeriod' label={<>
              Online Access Period
              <Popover title='Online Access Period' content={CommonPopoversContent.renderOnlineAccessPeriodPopoverContent()}>
                <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
              </Popover>
            </>}>
              <InputNumber min={1} />
            </Form.Item>

            <Form.Item name="onlineAccessPeriodUnity" label=" " style={{ marginLeft: 8, width: 100 }} initialValue="days">
              <Select>
                <Select.Option value="days">Days</Select.Option>
                <Select.Option value="hours">Hours</Select.Option>
                <Select.Option value="minutes">Minutes</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {!(isWebinar || isVILT) && (
            <>
              <Col span={7}>
                {this._renderDisabledTooltip(
                  <Form.Item name='courseProviderID' label={'Course Provider'} rules={[{ required: true, message: 'This field is required!' }]}>
                    <Select mode='single' disabled showSearch filterOption={(input, option) => {
                      const provider = (allowedOnlineCourseProviders || []).find((c) => option.value == c.id);
                      return `${provider.id} (${provider.type})`.toLowerCase().includes(input.toLowerCase());
                    }} {...this.limitedEditModeAttributes}>
                      {(allowedOnlineCourseProviders || []).map((c) => {
                        return <Select.Option value={c.id} key={c.id}>{c.id} ({c.type})</Select.Option>;
                      })}
                    </Select>
                  </Form.Item>)}
              </Col>
              <Col span={5}>
                {this._renderDisabledTooltip(
                  <Form.Item name='providerCourseTemplateID' label={'Online Course Template ID'} rules={[{ required: true, message: 'This field is required!' }]}>
                    <Input disabled/>
                  </Form.Item>
                )}
              </Col>
            </>
          )}

          {(isWebinar || isVILT) && (
            <Col span={17}>
              <Form.Item name='accessLink' label={'Launch URL'} rules={[{ required: true, message: 'This field is required!' }]} help="Enter the URL to be launched in a new browser tab when the user clicks the Launch button.">
                <Input />
              </Form.Item>
            </Col>
          )}
        </Row>
        {((this.props.app.isSysAdmin() || this.props.app.isAdmin()) && !isWebinar && !isVILT) && <Row gutter={32}>
          <Col span={4}>
            <Form.Item name='onlineCourseID' extra='This field will be auto generated if not specified!' label='Online Course ID'>
              <Input/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='onlineQuizID' extra='This field will be auto generated if not specified!' label='Online Quiz ID'>
              <Input/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='onlineGradeID' extra='This field will be auto generated if not specified!' label='Online Grade ID'>
              <Input/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='onlineScoreGradeID' extra='This field will be auto generated if not specified!' label='Optional Score Grade ID'>
              <Input/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name='checkGradeDelay' label={<>
              Grade Check Delay
              <Popover title='Grade Check Delay' content={CommonPopoversContent.renderGradeCheckDelayPopoverContent()}>
                <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
              </Popover>
            </>}>
              <InputNumber style={{ maxWidth: 140 }} min={-1}/>
            </Form.Item>
          </Col>
        </Row>}
      </div>
    );
  }
  _renderNotificationsSubform() {
    return (
      <div style={{ marginTop: 32 }}>
        <Divider orientation="left">Notifications</Divider>
        <Row gutter={32}>
          <Col>
            <Form.Item name='adminEmails' label={'Notification Emails'} rules={[{ required: false, message: 'This field is required!' }]} extra={'Enter one of more email addresses separated by comma. This emails will receive admin notifications of this session.'}>
              <Input placeholder="joedoe@example.com, joe2@example.com"/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={6}>
            <Form.Item name='shouldNotifyEnrolmentToAdmins' label={'Send enrolment emails to admins?'} valuePropName='checked'>
              <Switch />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name='notifyWhenSessionFull' label={'Send notifications when session becomes full?'} valuePropName='checked'>
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  }
  /* private UI */
  _renderDatesRow() {
    return <CommonSessionDraftFormDatesTable isRequired={this.state.type == Globals.Session_Type.SCHEDULED_PRESENTIAL || this.state.type == Globals.Session_Type.SCHEDULED_ONLINE_EXT || this.state.type == Globals.Session_Type.SCHEDULED_VILT || this.state.type == Globals.Session_Type.SCHEDULED_WEBINAR} {...Utils.propagateRef(this, 'datesTable')} />;
  }

  _renderDisabledTooltip(children) {
    return (
      <Tooltip title='This option is disabled at session level. If you want to change this, please, update the template or create a new one.'>
        {children}
      </Tooltip>
    )
  }

  /* private api */
  async _loadReferralNames() {
    if (this.state.possibleReferrals?.length <= 0) {
      this.setState({ referralOrgs: null });
      return;
    }
    let orgsResp = await this.props.app.organization.organization.searchOrganizationsByIDs({ orgIDs: this.state.possibleReferrals });
    if (orgsResp.statusCode == 200 && orgsResp.body?.orgs) {
      this.setState({ referralOrgs: orgsResp.body.orgs });
    } else this.props.app.alertController.showAPIErrorAlert(null, orgsResp);
  }
}
