import React from 'react';
import autoBind from 'react-autobind';
import { message, Steps } from 'antd';
//
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//
import CommonLicensePurchaseModal from './CommonLicensePurchaseModal';
import CommonCoursePurchaseModalSubStep_LoadingView from './CommonCoursePurchaseModal_CalendarSubSteps/CommonCoursePurchaseModalSubStep_LoadingView';
import CommonCoursePurchaseModalSubStep_SessionTypeSelection from './CommonCoursePurchaseModal_CalendarSubSteps/CommonCoursePurchaseModalSubStep_SessionTypeSelection';
import CommonCoursePurchaseModalSubStep_OnlineSessionSelection from './CommonCoursePurchaseModal_CalendarSubSteps/CommonCoursePurchaseModalSubStep_OnlineSessionSelection';
import CommonCoursePurchaseModalSubStep_NoSessionsAvailable from './CommonCoursePurchaseModal_CalendarSubSteps/CommonCoursePurchaseModalSubStep_NoSessionsAvailable';
import CommonCoursePurchaseModalSubStep_CalendarSessionSelection from './CommonCoursePurchaseModal_CalendarSubSteps/CommonCoursePurchaseModalSubStep_CalendarSessionSelection';
//
const SessionTypes = {
  NONSCHEDULABLE: 1,
  SCHEDULABLE: 2
};
//
export default class CommonCoursePurchaseModal extends CommonLicensePurchaseModal {
  constructor(props) {
    super(props, 'CALENDAR');
    autoBind(this);
    //this.state = { sessions: null, calendars: null, isLoading: false, selectedSessionType: null }
  }
  //Actions
  handleSessionTypeSelection(type) {
    this.setState({ selectedSessionType: type }, () => {
      if (type == SessionTypes.NONSCHEDULABLE) {
        const onlineSessions = (this.state.sessions?.filter((s) => (s.type == Globals.Session_Type.ONLINE || s.type == Globals.Session_Type.ONLINE_EXT)) || []);
        if (onlineSessions.length == 1) this.handleSessionSelection(onlineSessions[0].id);
      }
    });
  }
  async handleSessionSelection(sessionID) {
    let session = null;
    if (!this.state.sessions) {
      const sessionResp = await this.props.app.classroom.session.getSession(sessionID, false);
      if (sessionResp.statusCode == 200 && sessionResp.body) {
        session = sessionResp.body;
      }
    } else session = this.state.sessions?.find((s) => s.id == sessionID);
    this.setSessionID(sessionID, session);
    this.setState({ sessions: null, calendars: null, selectedSessionType: null }, () => {
      this.__sessionsFirstLoad = false;
    });
  }
  handleClose() { this.closeModal(); }
  /* Overwrites */
  handleAfterClose() {
    super.handleAfterClose();
    this.setState({ sessions: null, calendars: null, selectedSessionType: null }, () => {
      this.__sessionsFirstLoad = false;
    });
  }
  loadModalInfoWithCourseSpec(courseSpecs) {
    if (courseSpecs && courseSpecs.productID) this.loadModalInfo(courseSpecs.productID, null, courseSpecs);
    else this.loadModalInfo();
  }
  _getStepIndex() {
    if (this.getCurrentStep() == 'CALENDAR') return 0;
    return super._getStepIndex() + 1;
  }
  _renderStepperSteps() {
    return (
      <>
        <Steps.Step title="Sessions" />
        {super._renderStepperSteps()}
      </>
    );
  }
  _renderSelectedStep() {
    if (this.getCurrentStep() == 'CALENDAR') return this._renderCalendarView();
    return super._renderSelectedStep();
  }
  /* UI */
  _renderCalendarView() {
    const courseSpecs = this.getCurrentProductRelatedObject();
    if (this.state.isLoading || !this.state.sessions) {
      this._loadOnDemand();
      return (<CommonCoursePurchaseModalSubStep_LoadingView/>);
    } else if (!this.state.selectedSessionType) {
      if (!this.state.sessions || this.state.sessions.length <= 0) {
        return (<CommonCoursePurchaseModalSubStep_NoSessionsAvailable onCancel={this.handleClose} courseSpecs={courseSpecs}/>);
      } else {
        return (<CommonCoursePurchaseModalSubStep_SessionTypeSelection onSelect={this.handleSessionTypeSelection} onCancel={this.handleClose}
                                                                       courseSpecs={courseSpecs} app={this.props.app} sessions={this.state.sessions}/>);
      }
    } else if (this.state.sessions && this.state.selectedSessionType) {
      if (this.state.selectedSessionType == SessionTypes.NONSCHEDULABLE) {
        const onlineSessions = (this.state.sessions?.filter((s) => (s.type == Globals.Session_Type.ONLINE || s.type == Globals.Session_Type.ONLINE_EXT)) || []);
        return (<CommonCoursePurchaseModalSubStep_OnlineSessionSelection onSelect={this.handleSessionSelection} onCancel={this.handleClose}
                                                                         courseSpecs={courseSpecs} sessions={onlineSessions} app={this.props.app}/>);
      } else {
        const schedulableSessions = (this.state.sessions?.filter((s) => (s.type != Globals.Session_Type.ONLINE && s.type != Globals.Session_Type.ONLINE_EXT)) || []);
        return (<CommonCoursePurchaseModalSubStep_CalendarSessionSelection onSelect={this.handleSessionSelection} onCancel={this.handleClose}
                                                                           calendars={this.state.calendars} sessions={schedulableSessions} app={this.props.app}/>);
      }
    }
  }

  /* API calls */
  _loadOnDemand() {
    if (!this.state.sessions && !this.__sessionsFirstLoad && this.props.isVisible) {
      this.__sessionsFirstLoad = true;
      setTimeout(() => this._loadSessions(), 100); //this is done to avoid set state on render cycle!
    }
  }
  async _loadSessions() {
    this.startLoading();
    //Make request
    const courseSpecs = this.getCurrentProductRelatedObject();
    const sessionsResp = await this.props.app.classroom.session.getSessionList(Date.now(), Utils.timestampAfterYears(1), courseSpecs.id);
    if (!this._isMounted) return;
    //Response
    if (sessionsResp.statusCode == 200 && sessionsResp.body) {
      const { sessions, calendars } = sessionsResp.body;
      //Filter for usable sessions
      const usableSessions = sessions.filter((session) => {
        return !((session.state != Globals.Session_State.AVAILABLE && session.state != Globals.Session_State.LOCKED) ||
                (session.capacity > 0 && session.capacity - (session.enrolmentCount || 0) - session.reservedCapacity <= 0));
      }).filter(session => session.state != Globals.Session_State.LOCKED);
      const onlineCount = (usableSessions?.filter((s) => (s.type == Globals.Session_Type.ONLINE || s.type == Globals.Session_Type.ONLINE_EXT)) || []).length;
      const schedulable = (usableSessions?.filter((s) => (s.type != Globals.Session_Type.ONLINE && s.type != Globals.Session_Type.ONLINE_EXT)) || []).length;

      //Check if need to preselect type
      this.setState({ sessions: usableSessions, calendars, isLoading: false }, () => {
        const selectedSessionType = ((onlineCount == 0 && schedulable != 0) ? SessionTypes.SCHEDULABLE : ((onlineCount != 0 && schedulable == 0) ? SessionTypes.NONSCHEDULABLE : null));
        if (selectedSessionType) this.handleSessionTypeSelection(selectedSessionType);
      });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, sessionsResp);
      this.closeModal();
    }
  }
}
